<template>
  <div class="checkout-success-view Aligner">
    <div class="holder">
      <img src="@/assets/checkout-success.svg"/>
      <div class="font-size-lg font-weight-bold my-3 color-secondary">
        <slot name="title"></slot>
      </div>

      <div class="font-size-lg font-weight-bold my-3">
        <slot name="body"></slot>
      </div>

      <router-link to="{{back_url}}" class="btn btn-primary btn-spacy mt-2">
        {{back_label}}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessPage",
  props: {
    back_url: {
      type: String,
      default: () => {
        return "/";
      }
    },
    back_label: {
      type: String,
      default: () => {
        return "Go home";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.checkout-success-view {
  min-height: 100%;

  .holder {
    img {
      width: 70px;
    }

    max-width: 500px;
    text-align: center;
  }
}
</style>
