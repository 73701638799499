<template>
  <success-page v-if="payment_succeeded === true" back_url="/" :back_label="$t('checkout.go_home_btn')">
    <template v-slot:title>
      {{ $t("pay_online.payment_succeeded.title") }}
    </template>
    <template v-slot:body>
      {{ $t("pay_online.payment_succeeded.message") }}
    </template>
  </success-page>

  <div class="pay-view Aligner" v-if="payment_succeeded === false">
    <div class="holder">
      <template v-if="order !== null">
        <template v-if="state.collectjs_ready">

          <div class="font-size-md font-weight-bold">
            {{ $t("pay_online.title") }}
          </div>

          <div class="supported_cards">
            <img src="@/assets/payment_methods/supported_cards.png" alt="Supported cards">
          </div>

          <!--     FORM     -->
          <form @submit.prevent="handleSubmit">
            <div class="mb-3">
              <input type="text" class="form-control" id="card_holder" placeholder="Card Holder">
            </div>

            <!-- Card Number-->
            <div class="mb-3">
              <div id="ccnumber"></div>
            </div>

            <div class="row">
              <!-- Card Expiration -->
              <div class="col-6 mb-3">
                <div id="ccexp"></div>
              </div>

              <!-- CVV -->
              <div class="col-6 mb-3">
                <div id="cvv"></div>
              </div>
            </div>

            <div class="mb-3">
              <button type="submit" :disabled="state.isSubmitting" class="btn btn-submit btn-primary btn-spacy">
                <div>Pay now</div>
                <div class="font-size-sm"> {{formatPrice(order.currency, order.total_amount) }}</div>
              </button>
            </div>
          </form>


          <!--     Errors    -->
          <div class="errors mt-3" v-if="errors !== null">
            <alert type="danger" :dismissible="true" v-for="error in errors" :key="error.code">
              {{ error.message }}
            </alert>
          </div>
        </template>
        <template v-else>
          <alert type="danger" :dismissible="false">
            CollectJS is not configured.
          </alert>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {Promise} from "core-js";
import Alert from "@/components/Alert";
import formatPrice from "@/includes/currency";
import SuccessPage from "@/components/SuccessPage";
import {mapActions} from "vuex";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "PayShow",
  components: {SuccessPage, Alert},
  setup(){
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("pay_online.page_title"),
    })
  },
  data() {
    return {
      state: {
        isSubmitting: false,
        collectjs_ready: false,
      },
      errors: null,
      order: null,
      payment_token: null,
      payment_succeeded: false
    }
  },
  computed: {
    order_id: function () {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions(['setErrors']),
    async getOrder(order_id) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("orders_show", {
          order_id: order_id
        });

        window.axios.get(url).then(
            (response) => {
              resolve(response.data.data);
            },
            (error) => {
              this.errors = error.response.data.errors;
              this.setErrors(this.errors);
              reject(this.errors);
            }
        );
      })
    },
    async processPayment() {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("orders_process_payment", {
          'order_id': this.order_id
        });

        window.axios.post(url, {
          payment_token: this.payment_token
        }).then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              this.errors = error.response.data.errors;
              reject(this.errors);
            }
        );
      });
    },
    componentDidMount() {
      if (window.CollectJS !== undefined) {
        this.state.collectjs_ready = true;
        window.CollectJS.configure({
          variant: 'inline',
          styleSniffer: true,
          callback: (response) => {
            //On callback get response
            this.finishSubmit(response)
          },
          "googleFont": "Roboto:300,400",
          customCss: {
            'font-size': "1rem",
            'font-family': "Roboto",
            'font-weight': "400",
            'display': "block",
            'width': "100%",
            'padding': "6px 12px",
            'height': '40px',
            'line-height': "24px",
            'color': "#212529",
            'background-color': "#fff",
            'background-clip': "padding-box",
            'border': "1px solid #ced4da",
            '-webkit-appearance': "none",
            '-moz-appearance': "none",
            'appearance': "none",
            'border-radius': ".25rem",
            'transition': "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
          },
          fields: {
            ccnumber: {
              placeholder: 'CC Number',
              selector: '#ccnumber',
            },
            ccexp: {
              placeholder: 'MM / YY',
              selector: '#ccexp'
            },
            cvv: {
              placeholder: 'CVV',
              selector: '#cvv'
            }
          }
        });
      } else {
        this.state.collectjs_ready = false;
        console.log("CollectJS is not configured.");
      }
    },
    finishSubmit(response) {
      console.log(response);
      this.payment_token = response.token;
      this.state.isSubmitting = true;
      // SEND REQUEST TO TOKEN
      this.processPayment().then(() => {
        //Success
        this.state.isSubmitting = false;
        this.payment_succeeded = true;
      }, () => {
        //Error
        this.state.isSubmitting = false;
        this.payment_succeeded = false;
      })
    },
    handleSubmit(event) {
      event.preventDefault();
      window.CollectJS.startPaymentRequest();
    },
    formatPrice(currency, price) {
      return formatPrice(currency, price);
    },
    appendExternalCollectJsScript(){
      return new Promise((resolve) => {
        const el = document.getElementById('collectjs_script');
        if (!el) {
          let collectJsScript = document.createElement('script')
          collectJsScript.setAttribute('src', process.env.VUE_APP_COLLECT_JS_URL)
          collectJsScript.setAttribute("data-tokenization-key", process.env.VUE_APP_COLLECT_JS_TOKEN)
          collectJsScript.setAttribute("async", true)
          collectJsScript.setAttribute("defer", "defer")
          collectJsScript.setAttribute("id", "collectjs_script")
          document.head.appendChild(collectJsScript);
        }
        resolve();
      });
    }
  },
  mounted() {
    this.appendExternalCollectJsScript().then(() => {
      //Check if order is ready for payment
      this.getOrder(this.order_id).then((response) => {
        this.order = response;
        if (this.order.status === "payment_pending") {
          this.componentDidMount();
        } else {
          //Redirect home
          this.$router.replace("/");
        }
      }, () => {
        //Redirect home
        this.$router.replace("/");
      });
    });
  }
}
</script>

<style lang="scss" scoped>
.pay-view {
  min-height: 100%;

  .holder {
    min-width: 300px;
    max-width: 400px;

    .supported_cards {
      margin-top: map-get($spacings, "default");
      margin-bottom: map-get($spacings, "medium");

      img {
        max-width: 100%;
        max-height: 25px;
      }
    }

    form {
      .btn-submit {
        width: 100%;
      }
    }
  }
}
</style>
